<script>
  import { onMount } from "svelte"
  import { push } from "svelte-spa-router"
  import { whenScreenTransitionFunc } from "./stores.js"
  import axios from "axios"


  const API_URL = process.env.API_URL
  let testList = []
  let answers = []
  let ansGrade
  let ansGender
  let isLocked = false
  let questionnaireRefs = []
  let errorRefs = []
  let gradeRef = null
  let gradeErrorRef = null
  let genderRef = null
  let genderErrorRef = null

  let gradeList = [
    { id: 1, grade: "中学1年生" },
    { id: 2, grade: "中学2年生" },
    { id: 3, grade: "中学3年生" },
    { id: 4, grade: "高校1年生" },
    { id: 5, grade: "高校2年生" },
    { id: 6, grade: "高校3年生" },
    { id: 7, grade: "その他" },
  ]

  let genderList = [
    { id: 1, gender: "男性" },
    { id: 2, gender: "女性" },
    { id: 3, gender: "その他" },
    { id: 4, gender: "回答しない" },
  ]

  function nextScroll(num) {
    if (num < testList.length - 1) {
      questionnaireRefs[num + 1].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }

  onMount(async () => {
    whenScreenTransitionFunc()
    testList = await axios.get(API_URL + "/get_questionnaire")
    testList = testList.data["questionnaire"].Items
    testList.sort((a, b) => a.id - b.id)
    answers = testList.map(() => null);
  })

  async function questionAnswer() {
    let nextFlag = 0
    let gradeFlag = 0
    let genderFlag = 0
    isLocked = true
    if (ansGrade) {
      gradeRef.style.border = "medium solid #ffffff";
      gradeErrorRef.style.visibility = "hidden";
      gradeFlag = 1;
    } else if (!ansGrade) {
      gradeRef.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
      });
      gradeRef.style.border = "medium solid #f08080";
      gradeErrorRef.style.visibility = "visible";
      gradeFlag = 0;
    }
    if (ansGender) {
      genderRef.style.border = "medium solid #ffffff";
      genderErrorRef.style.visibility = "hidden";
      genderFlag = 1;
    } else if (!ansGender) {
      genderRef.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
      });
      genderRef.style.border = "medium solid #f08080";
      genderErrorRef.style.visibility = "visible";
      genderFlag = 0;
    }
    errorRefs.forEach(ref => {
      ref.style.visibility = "hidden";
    });
    questionnaireRefs.forEach(ref => {
      if (ref.tagName === "TABLE") {
      ref.style.backgroundColor = "#ffffff";
      } else {
      ref.style.border = "1px solid #cccccc";
      }
    })
    let unansweredIndices = answers.reduce((indices, answer, i) => {
      if (answer === null || answer === "") indices.push(i);
      return indices;
    }, []);
    if (unansweredIndices.length > 0) {
      unansweredIndices.forEach(i => {
        errorRefs[i].style.visibility = "visible";
        if(testList[i].choice) {
          questionnaireRefs[i].style.backgroundColor = "#f08080";
        } else {
            questionnaireRefs[i].style.border = "medium solid #f08080";
        }
      });
    } else {
      nextFlag = 1
    }
    if (gradeFlag && genderFlag) {
      if (nextFlag) {
      let processedAnswers = answers.map((answer, i) => {
        return testList[i].choice ? parseInt(answer) + 1 : answer;
      });
        const postResult = {
          action: "set_questionnaire",
          category: "questionnaire_result",
          id: sessionStorage.getItem("id"),
          result: processedAnswers,
          grade: ansGrade,
          gender: ansGender,
        }
        const response = await axios.post(API_URL + "/set_result", postResult)
        sessionStorage.setItem("questionnaireId", response["data"])
        push("/attention")
      } else {
        questionnaireRefs[unansweredIndices[0]].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
    isLocked = false
  }
  function nonGrade() {
    if (!ansGrade) {
      gradeRef.style.border = "medium solid #f08080"
      gradeErrorRef.style.visibility = "visible"
    } else {
      gradeRef.style.border = "medium solid #ffffff"
      gradeErrorRef.style.visibility = "hidden"
    }
  }
  function nonGender() {
    if (!ansGender) {
      genderRef.style.border = "medium solid #f08080"
      genderErrorRef.style.visibility = "visible"
    } else {
      genderRef.style.border = "medium solid #ffffff"
      genderErrorRef.style.visibility = "hidden"
    }
  }
</script>

<div class="wrapper">
  <h4>学年と性別を選んでください</h4>
  <div
    bind:this={gradeErrorRef}
    id="error-message-grade"
    style="visibility: hidden; color: red; text-align: left;"
  >
    学年を選択してください
  </div>
  <from method="grade-post">
    <div bind:this={gradeRef} class="grade-selecter">
      <label for="grade">学年</label>
      <select name="grade-select" bind:value={ansGrade} on:blur={nonGrade}>
        <option value="">選択してください</option>
        {#each gradeList as grade}
          <option value={grade}>{grade.grade}</option>
        {/each}
      </select>
    </div>
  </from>
  <div
    bind:this={genderErrorRef}
    id="error-message-gender"
    style="visibility: hidden; color: red; text-align: left;"
  >
    性別を選択してください
  </div>
  <from method="gender-post">
    <div bind:this={genderRef} class="gender-selecter">
      <label for="gender">性別</label>
      <select name="gender-select" bind:value={ansGender} on:blur={nonGender}>
        <option value="">選択してください</option>
        {#each genderList as gender}
          <option value={gender}>{gender.gender}</option>
        {/each}
      </select>
    </div>
  </from>
  <h4 style="margin-top: 15%; margin-bottom:5%;">
    あなたにもっとも当てはまると思うものを 4 つの中から選んでください。
  </h4>
  {#each testList as test, num}
    <div class="questionnaires">
      <div
        bind:this={errorRefs[num]}
        class="error-message"
        style="visibility: hidden; color: red; text-align: left;"
      >
        この設問に回答してください
      </div>
      <div class="questionnaire">
        <div
          style="height: fit-content; width: fit-content; padding: 0.5em; background-color: #3f51b5; color: white; border-radius: 4px;"
        >
          {"Q" + String(num + 1)}
        </div>
        <div style="padding: 0.5em; padding-left: 0.5em; margin: auto 0;">
          {test.text}
        </div>
      </div>
      {#if test.choice}
      <table bind:this={questionnaireRefs[num]} class="select-table" style="width: 100%; margin-top: 1em;">
        <tr>
          {#each test.choice as ansLevel, i}
            <div class="check-box-column">
              <label
                ><input
                  type="radio"
                  bind:group={answers[num]}
                  value={i}
                  on:click={() => nextScroll(num)}
                  style="transform:scale(1.2); margin: 10px;"
                />
                {ansLevel}
              </label>
            </div>
          {/each}
        </tr>
      </table>
      {:else}
        {#if test.kind === 'text'}
          <input bind:this={questionnaireRefs[num]} type="text" bind:value={answers[num]} style="margin-top: 1em; margin-left: 1em;" maxlength="50"/>
        {:else if test.kind === 'number'}
          <input bind:this={questionnaireRefs[num]} type="number" bind:value={answers[num]} style="margin-top: 1em; margin-left: 1em;" maxlength="50" />
        {/if}
      {/if}
    </div>
  {/each}
  <div class="next-button">
    <button class="indigo" disabled={isLocked} on:click={questionAnswer}>次へ</button>
  </div>
</div>

<style lang="scss">
  .wrapper {
    scroll-behavior: smooth;
  }
  .questionnaires {
    margin-bottom: 10%;
  }
  .questionnaire {
    display: grid;
    grid-template-columns: 3em auto;
    grid-template-rows: auto;
    place-items: center;
    justify-content: start;
    border-bottom: solid;
  }
  .check-box-column {
    flex-direction: column;
    margin-left: 1em;
  }
  .grade-selecter {
    width: fit-content;
  }
  .gender-selecter {
    width: fit-content;
  }
</style>
