<script>
  import { onMount } from "svelte"
  import { push } from "svelte-spa-router"
  import { whenScreenTransitionFunc } from "./stores.js"

  let page = 0

  onMount(async () => {
    whenScreenTransitionFunc()
  })

  function clickNext() {
    if (page) {
      push("/test")
    } else {
      page = 1
    }
  }
</script>

<div class="wrapper">
  <div class="notebox">
    {#if !page}
      <p>
        「成功」「勝利」のように「良いこと」を表現する単語と「失敗」「敗北」のように「悪いこと」を表現する単語が画面に出てきます。
      </p>

      <p>
        「良いこと」を意味する単語が出たら ○
        にタッチ、「悪いこと」を意味する単語が出たら × にタッチしてください。
      </p>
    {:else}
      <p>くわえて「数学」「理科」という単語が出てきます。</p>

      <p>
        途中の画面に表示される指示に従って、◯ または × をタッチしてください。
      </p>

      <p>回答はできるだけ早く、しかも間違えないようにやってください。</p>

      <p>次へを押して練習問題に進んでください</p>
    {/if}
  </div>

  <div class="next-button">
    <button class="indigo" on:click={clickNext}>次へ</button>
  </div>
</div>

<style lang="scss">
  .notebox {
    margin: 1em;
    min-height: 40vh;
    margin-top: 15vh;
  }
</style>
