import { writable } from "svelte/store"
import { push } from "svelte-spa-router"

function idCheck() {
  if (!sessionStorage.getItem("id")) {
    alert("利用者番号が入力されていません")
    push("/")
  }
}

export function whenScreenTransitionFunc() {
  idCheck()
  scrollTo(0, 0)
}

document.addEventListener(
  "dblclick",
  function (e) {
    e.preventDefault()
  },
  { passive: false }
)
