<script>
  import { onMount } from "svelte"
  import { whenScreenTransitionFunc } from "./stores.js"
  import axios from "axios"
  import Dialog from "./Dialog.svelte"

  const API_URL = process.env.API_URL
  const repetition = 5
  const firstTestPatterns = {
    0: { subject: "math", answer: 1 },
    1: { subject: "math", answer: 0 },
  }
  const exercises = [
    { answer: 1, text: "希望", kind: "1", id: "20221013175507" },
    { answer: 0, text: "不満", kind: "2", id: "20221013175521" },
    { answer: 1, text: "幸福", kind: "1", id: "20221013175507" },
    { answer: 1, text: "安心", kind: "1", id: "20221013175521" },
    { answer: 0, text: "最低", kind: "2", id: "20221013175530" },
  ]
  const maxChapter = 8
  const buttonChange = 2
  const countDown = 3
  let baseTime = 0
  let progressTime = 0
  let chapterTime = 0
  let eachChapterTime = {}
  let wrongAnswerNum = 0
  let wordCount = 0
  let chapterNum = 0
  let subjectCount = 0
  let patternId = 0
  let buttonPosition = Math.floor(Math.random() * 2)
  let test = {}
  let targetWord = {}
  let testList = []
  let resultList = []
  let targetWordList = []
  let positiveWordList = []
  let negativeWordList = []
  let tempPositiveWordList = []
  let tempNegativeWordList = []
  let kindList = [0, 1, 2]
  let startFlag = false
  let endFlag = false
  let showTest = false
  let showCount = false
  let isExercises = true
  let displayWarning = false
  let dialog

  onMount(async () => {
    whenScreenTransitionFunc()
    const response = await axios.get(API_URL + "/get_test")
    testList = response.data["test"].Items
    patternId = response.data["num_of_respondent"] % Object.keys(firstTestPatterns).length
    targetWordList = testList.filter((testList) => testList["kind"] == 0)
    positiveWordList = testList.filter((testList) => testList["kind"] == 1)
    negativeWordList = testList.filter((testList) => testList["kind"] == 2)
    const pattern = firstTestPatterns[patternId]
    searchTarget(pattern["subject"], pattern["answer"])
    document.getElementById("start-button").style.visibility = "visible"
    tempPositiveWordList = JSON.parse(JSON.stringify(positiveWordList))
    tempNegativeWordList = JSON.parse(JSON.stringify(negativeWordList))
  })

  function startTest() {
    showCount = true
    document.getElementById("start-button").style.display = "none"
    document.getElementById("text").style.display = "none"
    countDownFunc()
    setExercises()
  }

  function countDownFunc() {
    progressTime = 0
    startFlag = true
    let progressID = setInterval(() => {
      if (progressTime < countDown - 1) {
        progressTime++
      } else {
        baseTime = new Date()
        showTest = true
        showCount = false
        wordCount = 0
        clearInterval(progressID)
      }
    }, 1000)
  }

  function setExercises() {
    if (exercises[0]) {
      test = exercises[0]
      exercises.shift()
    } else {
      chapterNum++
      showTest = false
      setRandomTest()
    }
  }

  function setRandomTest() {
    let kind = 0
    if (kindList.length == 0) {
      kindList = [0, 1, 2]
      wordCount++
      if (wordCount >= repetition) {
        let targetAnswer
        let targetSubject
        showTest = false
        subjectCount++
        chapterNum++
        setEachChapterTime()
        if (targetWord["answer"] == 0) {
          targetAnswer = 1
        } else {
          targetAnswer = 0
        }
        searchTarget(targetWord["subject"], targetAnswer)
        if (subjectCount >= (maxChapter) / 2) {
          if (targetWord["subject"] == "math") {
            targetSubject = "science"
          } else {
            targetSubject = "math"
          }
          searchTarget(targetSubject, targetWord["answer"])
          subjectCount = 0
        }
        if (chapterNum <= maxChapter) {
          if (!((chapterNum - 1) % buttonChange)) {
            displayWarning = true
            buttonPosition = buttonPosition == 1 ? 0 : 1
            kind = Math.floor(Math.random() * (kindList.length - 1)) + 1
          }
        } else {
          endFlag = true
          postRequest()
        }
      }
    }
    if (!kind) {
      kind = Math.floor(Math.random() * kindList.length)
    }
    if (kindList[kind] == 0) {
      test = targetWord
    } else if (kindList[kind] == 1) {
      if (!tempPositiveWordList.length) {
        tempPositiveWordList = JSON.parse(JSON.stringify(positiveWordList))
      }
      const index = Math.floor(Math.random() * tempPositiveWordList.length)
      test = tempPositiveWordList[index]
      tempPositiveWordList.splice(index, 1)
    } else {
      if (!tempNegativeWordList.length) {
        tempNegativeWordList = JSON.parse(JSON.stringify(negativeWordList))
      }
      const index = Math.floor(Math.random() * tempNegativeWordList.length)
      test = tempNegativeWordList[index]
      tempNegativeWordList.splice(index, 1)
    }
    kindList.splice(kind, 1)
  }

  function searchTarget(_subject, _answer) {
    targetWord = targetWordList.filter(
      (targetWordList) =>
        targetWordList["subject"] == _subject &&
        targetWordList["answer"] == _answer
    )[0]
  }

  function nextChapter() {
    showCount = true
    chapterTime = 0
    displayWarning = false
    countDownFunc()
  }

  async function answer(event) {
    let result = {}
    const elapsedTime = (new Date() - baseTime) / 1000
    if (test.kind == 0) {
      chapterTime += elapsedTime
    }
    result = {
      testId: test.id,
      time: elapsedTime,
      answer: event.target.value,
      text: test.text,
      kind: test.kind,
      buttonPosition: buttonPosition,
    }
    if (event.target.value != test.answer) {
      wrongAnswerNum += 1
      result["correction"] = 0
    } else {
      result["correction"] = 1
    }
    resultList.push(result)
    if (chapterNum) {
      setRandomTest()
    } else {
      setExercises()
    }
    document.getElementById("question").style.visibility = "hidden"
    window.setTimeout(function () {
      if (document.getElementById("question")) {
        document.getElementById("question").style.visibility = "visible"
      }
      baseTime = new Date()
    }, 300)
  }

  function setEachChapterTime() {
    let chapterName = ""
    chapterName += targetWord.subject
    chapterName +=
      targetWord.answer == 1 ? "PositiveAverageTime" : "NegativeAverageTime"
    if (!eachChapterTime[chapterName]) {
      eachChapterTime[chapterName] = 0
    }
    eachChapterTime[chapterName] += chapterTime
  }

  async function postRequest() {
    let totalTime = 0
    let adjustedChapterTime = {}
    Object.values(eachChapterTime).forEach((value) => {
      totalTime += value
    })
    Object.keys(eachChapterTime).forEach((key) => {
      adjustedChapterTime[key] = eachChapterTime[key] / (repetition * 2)
    })
    const postResult = {
      action: "set_test",
      category: "test_result",
      id: sessionStorage.getItem("id"),
      testPattern: patternId + 1,
      result: resultList,
      averageTime: totalTime / ((maxChapter) * repetition),
      totalTime: totalTime,
      wrongAnswerNum: wrongAnswerNum,
      questionnaireId: sessionStorage.getItem("questionnaireId"),
    }
    Object.assign(postResult, adjustedChapterTime)
    try{
      await axios.post(API_URL + "/set_result", postResult)
    } catch {
      dialog.showModal()
    }
  }
</script>

{#if startFlag && showTest && !chapterNum}
  <div style="opacity: 0.8;">練習中</div>
{:else if startFlag && showTest && chapterNum}
  <div style="opacity: 0.8; text-align: center;">
    <span>「{targetWord["text"]}」</span>
    が出たら
    <span>
      {#if targetWord["answer"]}○
      {:else}×
      {/if}
    </span>
    にタッチ
  </div>
{/if}
<div class="wrapper">
  {#if endFlag}
    <div class="text-area">
      <h4>
        <p>お疲れ様でした。</p>
        <p>これでテストは終了です。</p>
        <p>ブラウザを閉じてください。</p>
      </h4>
    </div>
  {:else if startFlag}
    {#if showTest}
      {#if test}
        <div id="question">
          {test.text}
        </div>
      {/if}
      <div
        style="display: flex; justify-content: space-between; margin: 7vh 2em 0; "
      >
        {#if buttonPosition}
          <button on:click={answer} class="answer" value="1">
            <svg
              width="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="35"
                fill="none"
                stroke="#4d4d4d"
                stroke-width="10"
              />
            </svg>
          </button>
          <button on:click={answer} class="answer" value="0">
            <svg
              width="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20"
                y1="20"
                x2="80"
                y2="80"
                stroke="#4d4d4d"
                stroke-width="10"
              />
              <line
                x1="80"
                y1="20"
                x2="20"
                y2="80"
                stroke="#4d4d4d"
                stroke-width="10"
              />
            </svg>
          </button>
        {:else}
          <button on:click={answer} class="answer" value="0">
            <svg
              width="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20"
                y1="20"
                x2="80"
                y2="80"
                stroke="#4d4d4d"
                stroke-width="10"
              />
              <line
                x1="80"
                y1="20"
                x2="20"
                y2="80"
                stroke="#4d4d4d"
                stroke-width="10"
              />
            </svg>
          </button>
          <button on:click={answer} class="answer" value="1">
            <svg
              width="100%"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="50"
                cy="50"
                r="35"
                fill="none"
                stroke="#4d4d4d"
                stroke-width="10"
              />
            </svg>
          </button>
        {/if}
      </div>
    {:else if showCount}
      <div class="text-area" style="margin-top: 40vh">
        <p id="countdown">{countDown - progressTime}</p>
      </div>
    {:else if isExercises}
      <div class="text-area">
        <div style="margin-bottom: 4em">
          <p>練習問題終了です。</p>
          <p>「次へ」を押すと本番に進みます。</p>
        </div>
        <button
          class="indigo"
          style="width: 50vw; height: 3em;"
          on:click={() => {
            isExercises = false
          }}>次へ</button
        >
        <div style="margin-top: 50px; font-size: 0.9em;">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            width="20"
            fill="#4d4d4d"
            style="vertical-align: sub;"
            ><path
              d="M10.021 10.458ZM2.354 17.5q-.5 0-.76-.438-.261-.437-.011-.874L9.25 2.979q.25-.437.75-.437t.75.437l7.667 13.209q.25.437-.011.874-.26.438-.76.438Zm7.667-9.146q-.354 0-.615.261-.26.26-.26.614v2.333q0 .355.26.615.261.261.615.261t.614-.261q.261-.26.261-.615V9.229q0-.354-.261-.614-.26-.261-.614-.261Zm0 6.646q.354 0 .614-.26.261-.261.261-.615t-.261-.615q-.26-.26-.614-.26t-.615.26q-.26.261-.26.615t.26.615q.261.26.615.26Zm-6.167.75h12.292L10 5.167Z"
            /></svg
          >
          <span> ブラウザの戻るボタンは使用しないでください。 </span>
        </div>
      </div>
    {:else}
      <div class="text-area" style="margin-top: 15vh">
        <p style="font-size: 1.5em">問題{chapterNum}</p>
        <div>「良いこと」が出たら〇 にタッチ</div>
        <div>「悪いこと」が出たら × にタッチ</div>
        <h4 style="margin-bottom: 4em; margin-top: 0;">
          <span style="font-size: 1.5em">「{targetWord["text"]}」</span>
          が出たら
          <span style="font-size: 2em">
            {#if targetWord["answer"]}○
            {:else}×
            {/if}
          </span>
          にタッチ
        </h4>
        {#if displayWarning}
          <div style="margin-top: -3em; margin-bottom:2em; font-size: 0.9em;">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              width="20"
              fill="#4d4d4d"
              style="vertical-align: sub;"
              ><path
                d="M10.021 10.458ZM2.354 17.5q-.5 0-.76-.438-.261-.437-.011-.874L9.25 2.979q.25-.437.75-.437t.75.437l7.667 13.209q.25.437-.011.874-.26.438-.76.438Zm7.667-9.146q-.354 0-.615.261-.26.26-.26.614v2.333q0 .355.26.615.261.261.615.261t.614-.261q.261-.26.261-.615V9.229q0-.354-.261-.614-.26-.261-.614-.261Zm0 6.646q.354 0 .614-.26.261-.261.261-.615t-.261-.615q-.26-.26-.614-.26t-.615.26q-.26.261-.26.615t.26.615q.261.26.615.26Zm-6.167.75h12.292L10 5.167Z"
              /></svg
            >
            <span> ◯、× の位置が入れ替わります </span>
          </div>
        {/if}
        <button
          class="indigo"
          style="width: 50vw; height: 3em;"
          on:click={nextChapter}>次へ</button
        >
      </div>
    {/if}
  {:else}
    <div class="text-area" style="margin-top: 15vh">
      <p style="font-size: 1.5em">練習問題</p>
      <div style="margin-bottom: 4em">
        <p id="text">ボタンを押すと{countDown}秒後にテストが始まります。</p>
        <p>最初は練習問題です。</p>
      </div>
      <button
        id="start-button"
        class="indigo"
        style="width: 50vw; height: 3em; visibility: hidden;"
        on:click={startTest}>スタート</button
      >
      <div style="margin-top: 50px; font-size: 0.9em;">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="20"
          fill="#4d4d4d"
          style="vertical-align: sub;"
          ><path
            d="M10.021 10.458ZM2.354 17.5q-.5 0-.76-.438-.261-.437-.011-.874L9.25 2.979q.25-.437.75-.437t.75.437l7.667 13.209q.25.437-.011.874-.26.438-.76.438Zm7.667-9.146q-.354 0-.615.261-.26.26-.26.614v2.333q0 .355.26.615.261.261.615.261t.614-.261q.261-.26.261-.615V9.229q0-.354-.261-.614-.26-.261-.614-.261Zm0 6.646q.354 0 .614-.26.261-.261.261-.615t-.261-.615q-.26-.26-.614-.26t-.615.26q-.26.261-.26.615t.26.615q.261.26.615.26Zm-6.167.75h12.292L10 5.167Z"
          /></svg
        >
        <span> ブラウザの戻るボタンは使用しないでください。 </span>
      </div>
    </div>
  {/if}
  {#if startFlag && showTest && chapterNum}
    <div style="text-align: end">問題{chapterNum}/8</div>
  {/if}
  <Dialog
    bind:dialog
    text="結果の送信に失敗しました。結果を再送しますか？"
    closeText="終了"
    nextText="再送"
    on:closeDialog={() => dialog.close()}
    on:nextDialog={() => {
      dialog.close()
      postRequest()
    }}
  />
</div>

<style lang="scss">
  svg {
    pointer-events: none;
  }

  #question {
    width: 300px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    text-align: center;
    font-size: 4em;
  }

  #countdown {
    font-size: 4em;
  }

  .answer {
    width: 30vw;
    height: 30vw;
    font-size: 4em;
  }

  .text-area {
    text-align: center;
    margin-top: 25vh;
  }
</style>
