<script>
  import { createEventDispatcher } from "svelte"
  export let dialog // （←ポイント） 要素をバインドすることで親側で表示・閉じるの制御が可能に
  export let idNumber
  export let text
  export let closeText
  export let nextText

  const dispatch = createEventDispatcher()
  function clickClose() {
    dispatch("closeDialog")
  }
  function clickNext() {
    dispatch("nextDialog")
  }
</script>

<dialog bind:this={dialog}>
  <div class="inner">
    {#if idNumber}
      <h2>{idNumber}</h2>
    {/if}
    <p>{text}</p>
    <div class="buttons">
      <button class="back-dialog-button" on:click={clickClose}> {closeText} </button>
      <button class="next-dialog-button indigo" on:click={clickNext}>
        {nextText}
      </button>
    </div>
  </div>
</dialog>

<style>
  .buttons {
    display: flex;
    justify-content: center;
  }
  button {
    width: auto;
  }
  .back-dialog-button {
    margin-right: 5vh;
    color: black;
    outline-color: #3f51b5;
  }
  .next-dialog-button {
    margin-left: 5vh;
  }
  .inner {
    text-align: center;
    word-break: keep-all;
  }
</style>
