<script>
  import { push } from "svelte-spa-router"
  import { onMount } from "svelte"
  import Dialog from "./Dialog.svelte"

  const idPattern = /^[0-9a-zA-Z]{1,30}$/
  let tempId = ""
  let dialog

  onMount(async () => {
    scrollTo(0, 0)
  })

  async function handleKeydown(event) {
    if (checkId()) {
      sessionStorage.setItem("id", tempId)
      openDialog()
    } else {
      document.getElementById("error-message").style.visibility = "visible"
    }
  }

  function openDialog() {
    dialog.showModal()
    dialog.addEventListener("click", function (event) {
      if (event.target === dialog) {
        dialog.close()
      }
    })
  }

  function closeDialog() {
    dialog.close()
  }

  function nextDialog() {
    push("/questionnaire")
  }

  function checkId() {
    return tempId.match(idPattern)
  }
</script>

<div class="wrapper">
  <div id="title">
    <h1>学習応援アプリ</h1>
  </div>
  <div id="agreement">
    <p>
      学習支援アプリを開発するために、教科や学校生活に対する顕在的な態度や潜在的な態度を測定します。
    </p>
    <p>
      個人が特定されることはありません。<br
      />同意される方は利用者番号を入力して確認ボタンを押してください。
    </p>
  </div>
  <div id="input-id">
    <p style="font-weight: bold">利用者番号入力</p>
    <input id="input-area" bind:value={tempId} />
    <div id="error-message" style="visibility: hidden; color: red">
      入力が不正です
    </div>
  </div>
  <div class="confirmation-button">
    <button class="indigo" on:click={handleKeydown}>確認</button>
    <Dialog
      bind:dialog
      idNumber={tempId}
      text="番号に間違いがなければ進んでください"
      closeText="戻る"
      nextText="次へ"
      on:closeDialog={closeDialog}
      on:nextDialog={nextDialog}
    />
  </div>
</div>

<style lang="scss">
  h1 {
    margin-bottom: 10vh;
  }
  .wrapper {
    margin-left: 4vh;
    margin-right: 4vh;
  }
  #input-id {
    text-align: center;
    margin-top: 2em;
  }
  #input-area {
    width: 15em;
    height: 3em;
    padding-left: 2vh;
    padding-right: 2vh;
  }
  #title {
    text-align: center;
  }
  #agreement {
    margin: 1em;
  }
  .confirmation-button {
    text-align: center;
    margin-top: 10vh;
  }
</style>
