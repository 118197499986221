<script>
  import Router from "svelte-spa-router"
  import InputId from "./InputId.svelte"
  import Attention from "./Attention.svelte"
  import Questionnaire from "./Questionnaire.svelte"
  import Test from "./Test.svelte"

  const routes = {
    "/": InputId,
    "/questionnaire": Questionnaire,
    "/attention": Attention,
    "/test": Test,
  }
</script>

<Router {routes} />
